.collecting-rule__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.collecting-rule__left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.collecting-rule__partner-logo-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.collecting-rule__partner-logo {
  max-width: 100%;
  max-height: 100%;
}

.collecting-rule__partner-name {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
}

.collecting-rule__right {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 3px 3px 3px 8px;
  background-color: var(--light-grey);
}

.collecting-rule__text {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.collecting-rule__logo-earn-green {
    align-self: center;
}