.voucher-preview__header {
  margin: 0 auto;
  width: 100%;
  box-shadow: 0 6px 16px 0 rgba(69, 69, 85, 0.08);
}

.voucher-content {
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
  padding-bottom: 200px;
  padding-top: 38px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.voucher__mobile-list-view {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 71px 0;
}

.voucher__slider-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.voucher__list {
  box-shadow: 0 1px 6px 0 rgba(69, 69, 85, 0.06),
    0 27px 53px -11px rgba(69, 69, 85, 0.1);
}

.voucher__mobile-details-view {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.voucher-content__slider-list-container {
  width: 100%;
  padding-top: 51px;
}

.voucher__mobile__smartphone-container {
  height: 100%;
}

.voucher__mobile__smartphone-overlay {
  width: 100%;
  max-width: 431px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.voucher__mobile__details-container {
  width: calc(375 / 1180 * 100%);
  height: 100%;
}

.voucher__mobile__smartphone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.voucher__mobile__smartphone-overlay-img {
  position: relative;
  max-width: 100%;
}

@media print {
  .voucher__mobile-details-view {
    padding-bottom: 10px;
  }

  .voucher__mobile__smartphone-container {
    margin-bottom: 400px;
  }

  .voucher-content,
  .voucher-preview__header {
    padding: 0 50px;
  }
}
