.mobile-details__container {
  border-top-left-radius: 12px;
  border: solid 1px var(--outlineGrey);
  border-radius: 12px;
  width: 100%;
  word-break: word-break;
  white-space: pre-line;
  background: #f6f6f9;
}

.mobile-details__container-smartphone {
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 95px);
  padding: 0 30px;
  margin-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  -ms-overflow-style: none;
}

.mobile-details__container-smartphone::-webkit-scrollbar {
  width: 0px;
}

.mobile-details__status-bar {
  width: 100%;
  height: 48px;
  overflow: hidden;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

.mobile-details__top-content {
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgb(69 69 85 / 8%);
  padding: 0 16px 16px;
  border-top: solid 1px var(--outlineGrey);
  margin: -4px 0 10px;
  background-color: white;
  position: relative;
}

.mobile-details__help-text-container {
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 20px;
  margin-top: 16px;
}

.mobile-details__help-text {
  font-size: 12px;
  color: #ffffff;
  width: 80%;
  margin: 0;
  font-family: 'Mulish-Medium';
}

.mobile-details__help-text-link {
  font-size: 12px;
  color: #d6ab53;
  font-family: 'Mulish-Bold';
}

.mobile-details__help-text-link:hover {
  text-decoration: none;
  color: unset;
}

.mobile-details__number-of-redeems {
  font-size: 12px;
  font-weight: 600;
  color: var(--darkGrey);
  margin: 0;
}

.mobile-details__headline-text {
  font-size: 14px;
  font-weight: bold;
  color: var(--dark);
  margin: 0;
  padding-top: 12px;
}

.mobile-details__description {
  margin-bottom: 20px;
}

.mobile-details__description-text,
.mobile-details__online-description-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark);
  margin: 0;
}

.mobile-details__description-text {
  margin-top: 12px;
  white-space: break-spaces;
}

.mobile-details__bottom-content {
  border-top: solid 1px var(--outlineGrey);
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgb(69 69 85 / 8%);
  padding: 16px;
  margin-bottom: 10px;
  position: relative;
  background: white;
}

.mobile-details__terms-container {
  padding-top: 16px;
  border-top: solid 1px var(--outlineGrey);
}

.mobile-details__terms-title-wrapper {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.mobile-details__terms-title-left {
  display: flex;
}

.mobile-details__terms-title-right {
  display: flex;
}

.mobile-details__terms-title {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark);
  padding-left: 8px;
  height: 20px;
  margin: 3px 0 12px;
}

.mobile-details__terms-description {
  font-size: 12px;
  font-weight: 500;
  color: var(--darkGrey);
  word-break: break-word;
  align-self: center;
}

.mobile-details__online-description-text {
  padding-top: 15px;
}

.mobile-details__online-btn {
  margin-top: 20px;
  border-radius: 20px;
  padding: 10px 24px;
  background-image: linear-gradient(to right, #d6ab53, #e3bf74 99%);
  border: none;
  width: 100%;
}

.mobile-details__online-btn-label {
  font-size: 14px;
  font-weight: 800;
}

.mobile-details__balance-info {
  border-top: solid 1px var(--outlineGrey);
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgb(69 69 85 / 8%);
  padding: 16px;
  margin-bottom: 10px;
  position: relative;
  background: white;
}

.mobile-details__balance-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  border-bottom: dashed 1px var(--outlineGrey);
}

.mobile-details__content {
  margin: 0 16px;
}

.mobile-details__smartphone-footer {
  position: sticky;
  bottom: 0;
  width: 339px;
}

.mobile-details__scrollable-content {
  position: relative;
  z-index: -1;
  background: #f6f6f9;
}

.mobile-details__arrow-icon {
  transform: rotate(180deg);
}

.mobile-details__barcode {
  width: 202px;
  margin: 0 auto;
}

.mobile-details__barcode-img {
  max-width: 100%;
}

.mobile-details__barcode-wrapper {
  width: 100%;
  padding: 15px 0;
  border-radius: 12px;
  border: dashed 1px #e4e4e7;
}

.mobile-details__status-bar img {
  max-width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.mobile-details__status-bar svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.mobile-details__barcode-text {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobile-details__barcode-text p {
  margin: 10px 0 0;
  font-family: 'Mulish-Bold';
  font-size: 20px;
  color: #454555;
}
