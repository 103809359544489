.voucher-item__header,
.voucher-item__header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.voucher-item__header {
  padding: 10px 17px 10px 16px;
}
.voucher-item__header-mobile {
  padding-top: 15px;
}

.voucher-item__header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.voucher-item__partner-logo-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.voucher-item__partner-logo {
  max-width: 100%;
  max-height: 100%;
}

.voucher-item__name {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
}

.voucher-item__heart-icn {
  width: 26px;
}

.voucher-item__heart-icn-mobile {
  border-radius: 50%;
  border: solid 1px var(--outlineGrey);
  padding: 5px;
  margin-left: 10px;
}

.voucher-item__header-right-mobile {
  border-radius: 50%;
  border: solid 1px var(--outlineGrey);
  padding: 2px;
}

.voucher-item__header-right {
  display: flex;
  flex-direction: row;
}

.voucher-item__webshop-container {
  display: flex;
  border-radius: 20px;
  border: solid 1px var(--outlineGrey);
  align-items: center;
  padding: 5px;
}

.voucher-item__webshop-text {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: var(--dark);
  padding: 5px;
}

.voucher-item__header-container,
.voucher-item__header-container-mobile {
  display: flex;
  flex-direction: column;
}

@media print {
  .voucher-item__header-container {
    /* border: solid 1px var(--outlineGrey); */
  }
}
