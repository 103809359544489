.footer {
  padding: 23px 0 8px;
}

.footer_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_button {
  box-shadow: 0 6px 16px 0 rgba(69, 69, 85, 0.08);
}

.footer_round {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_close {
  background: linear-gradient(to right, #d6ab53, #e3bf74 99%);
}

.footer_heart {
  background: white;
}

.footer_meine-jo-karte {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #d6ab53, #e3bf74 99%);
  border-radius: 27px;
  padding: 9px 15.9px 9px 12px;
}

.footer_card-icon {
  margin-right: 10px;
}

.footer_text {
  margin: 0;
  color: white;
  font-family: 'Muli-ExtraBold';
  margin-left: 10px;
}

.footer_grey-line {
  width: 134px;
  height: 5px;
  background: #b8b8be;
  margin: 0 auto;
  margin-top: 25px;
}
