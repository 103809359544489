.voucher-preview-item-title__container {
    display: flex;
    padding-bottom: 36px;
  }
  
  .voucher-preview-item-title__text {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-left: 12px;
  }