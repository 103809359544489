.mobile-details__header-container {
    display: flex;
    flex-direction: column;
}

.mobile-details__header-top {
    background-image: url("../../../../images/oes-bg-top.png");
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.mobile-details__oes {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: var(--dark);
}

.mobile-details__header-title {
    margin: 0;
    font-size: 10px;
    color: var(--darkGrey);
}

.mobile-details__right {
    visibility: hidden;
}