@font-face {
  font-family: Muli;
  src: url('./resources/Muli-Regular.ttf');
}

@font-face {
  font-family: Muli-ExtraBold;
  src: url('./resources/Muli-ExtraBold.ttf');
}

@font-face {
  font-family: Mulish-Medium;
  src: url('./resources/Mulish-Medium.ttf');
}

@font-face {
  font-family: Mulish-Bold;
  src: url('./resources/Mulish-Bold.ttf');
}

@font-face {
  font-family: Muli-SemiBold;
  src: url('./resources/Muli-SemiBold.ttf');
}

body {
  font-family: Muli;
  --voucher-type-burn: #4a8bc0;
  --voucher-type-earn: #8cb42c;
  --voucher-type-show-your-card: #6c80db;
  --outlineGrey: #e4e4e7;
  --light-grey: #f6f6f9;
  --dark: #454555;
  --darkGrey: #8f8f99;
}
