.voucher-header__container {
  padding: 19px 0 34px 0;
  display: flex;
  flex-direction: row;
  max-width: 1180px;
  margin: 0 auto;
}

.voucher-header__logo-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.voucher__logo {
  max-width: 100%;
  max-height: 100%;
}

.voucher-header__info-container {
  padding: 12px 0 12px 24px;
  display: flex;
  flex-grow: 100;
  justify-content: space-between;
}
.voucher-header__name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.voucher-header__duration {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
}

.voucher-header__opid,
.voucher-header__ppid {
  margin: 6px 0 15px 0;
  font-size: 14px;
}

.header__bottom-part__info__duration {
  font-size: 14px;
  font-weight: 500;
  color: var(--dark);
}

.voucher_header__info-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.voucher-header__pdf-btn {
  border-radius: 20px;
  padding: 10px 24px;
  background-image: linear-gradient(to right, #d6ab53, #e3bf74 99%);
  border: none;
}

.voucher-header__pdf-btn-label {
  font-size: 14px;
  font-weight: 800;
}

.voucher-header__loader {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

@media print {
  .voucher-header__pdf {
    visibility: hidden;
  }
}
