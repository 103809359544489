.container-list {
  max-width: 375px;
  border-radius: 12px;
  box-shadow: 0 6px 16px 0 rgba(69, 69, 85, 0.08);
}
.container-slider {
  width: 100%;
  max-width: 327px;
}

.container-slider-mobile {
  width: 100%;
  max-width: 327px;
}

.voucher-item__slides-icn {
  align-self: center;
  margin-top: 12px;
}

.voucher-item__img-container {
  max-height: 165px;
}

.voucher-item__img-container__slider {
  height: 148px;
}

.voucher-item__img-container__mobile {
  max-height: 165px;
}

.voucher-item__img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.voucher-item__img-square,
.voucher-item__img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  display: block;
}

.voucher-item__details {
  padding: 16px 16px 20px 16px;
}
.voucher-item__details-mobile {
  padding: 16px 0 20px;
  text-align: center;
}

.voucher-item__description {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 14px;
}

.voucher-item__details-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.voucher-item__details-bottom-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.voucher-item__duration-container {
  display: flex;
  align-items: center;
}

.voucher-item__duration {
  margin-right: 5px;
}

.voucher-item__duration-text {
  margin: 0;
  font-size: 10px;
  color: #454555;
  font-family: 'Muli-SemiBold';
}

.voucher-item__content {
  border-radius: 12px;
}

.voucher-item__container__barcode-no-img {
  max-width: 327px;
  max-height: 143px;
  position: relative;
}

.voucher-item__barcode-placeholder {
  max-height: 100%;
  max-width: 100%;
}

.voucher-item__barcode-number {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.voucher-item__img-container__barcode {
  max-height: 148px;
  padding: 5px 54px;
  border: dashed 1px #e4e4e7;
  border-radius: 12px;
  height: 106px;
  display: flex;
  align-items: center;
}

.voucher-item__exclusive-mobile {
  padding: 0 16px 16px;
}

.voucher-item__exclusive {
  margin-bottom: 16px;
}

@media print {
  .container-list {
    border: 1px solid #e4e4e7;
  }
}
