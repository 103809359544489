.header__container {
  display: flex;
  flex-direction: column;
}

.header__top-part {
  border-bottom: solid 1px var(--outlineGrey);
  padding: 9px 0 19px 0;
  display: flex;
  flex-direction: row;
  max-width: 1180px;
  margin: 0 auto;
}

.header__jo-logo {
  width: 54px;
  height: 54px;
  fill: #d6ab53;
}

.header__title {
  padding: 15px 0 0 20px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark);
  margin-bottom: 0;
}

.header__flex {
  display: flex;
  flex-direction: column;
}

.header__flex__error {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 2px;
}

.header__error__message {
  color: #db475b;
  font-family: Muli;
  font-size: 12px;
  padding-left: 8px;
  margin-bottom: 0;
}
